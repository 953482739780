import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

const Logo = () => (
  <StaticQuery
    query={graphql`
       query {
          image : file(relativePath: { eq: "thibi-logo.png" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fixed(width: 130) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
    render={data => <Img fixed={data.image.childImageSharp.fixed} />}
  />
)
export default Logo